import React, { useEffect, useState } from "react";
//
// const scoreDict = {};
// const gamesDict = {};
//
// schedule.forEach(month => {
//     month.games.forEach(game => {
//         game.teams.forEach((team, index) => {
//             // Extracting individual names from team string
//             let names = team.split("(");
//             names.forEach(name => {
//                 name = name.replace(")", "").trim(); // Remove the closing parenthesis if any
//                 if (!scoreDict[name]) scoreDict[name] = 0; // Initialize the person's point if not done yet
//                 scoreDict[name] += game.score[index]; // Add the score to the person's total points
//             });
//         });
//     });
// });
//
// schedule.forEach(month => {
//     month.games.forEach(game => {
//         game.teams.forEach((team, index) => {
//             // Extracting individual names from team string
//             let names = team.split("(");
//             names.forEach(name => {
//                 name = name.replace(")", "").trim(); // Remove the closing parenthesis if any
//                 if (!gamesDict[name]) gamesDict[name] = 0; // Initialize the person's point if not done yet
//                 gamesDict[name] += 1; // Add the score to the person's total points
//             });
//         });
//     });
// });


function Scoreboard({players, handleClose, gamesDict, scoreDict}) {
    const [scores, setScores] = useState({});

    useEffect(() => {


        let sortedArray = Object.entries(scoreDict).sort((a, b) => b[1] - a[1]);

        let sortedObj = Object.fromEntries(sortedArray);
        setScores(sortedObj);
    }, [])

    return (
        <div className="scoreboard">
            <h2 style={{color: '#8a4cfc', textAlign: 'center', fontFamily: 'Arial'}}>Scorebord</h2>
            <table style={{
                borderCollapse: 'separate',
                borderSpacing: '10px',
                margin: 'auto',
                fontFamily: 'Arial',
                fontSize: '18px',
                textAlign: 'center'
            }}>
                <thead>
                <tr style={{fontWeight: 'bold', color: '#8a4cfc'}}>
                    <th>Naam</th>
                    <th>Score</th>
                    <th>Aant. potjes</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(scores).map(([player, score], index) => (
                    <tr key={index} style={index % 2 === 0 ? {
                        backgroundColor: '#FFEF9F',
                        color: '#8a4cfc'
                    } : {backgroundColor: '#FFEB75', color: '#8a4cfc'}}>
                        <td>{player}</td>
                        <td>{score}</td>
                        <td>{gamesDict[player]}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}>
                <button style={{
                    color: 'white',
                    backgroundColor: '#8a4cfc',
                    border: 'none',
                    padding: '10px 20px',
                    textAlign: 'center',
                    textDecoration: 'none',
                    display: 'inline-block',
                    fontSize: '16px',
                    margin: '10px 2px',
                    cursor: 'pointer'
                }} onClick={handleClose}>Sluit scorebord
                </button>
            </div>
        </div>
    );
}

export default Scoreboard;
