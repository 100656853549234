import React, {useState} from 'react';

function Game({date, teams, score, isCurrent, isPast}) {
    const [isOpen, setIsOpen] = useState(false);

    const formatName = (name) => {
        const split = name.split("(")
        if (split.length > 1) {
            console.log(split)
            return (
                <div style={{display: "flex", flexDirection: "row", paddingRight: "5px", paddingLeft: "5px"}}>
                    <strike>{split[0]}</strike>
                    <div>({split[1]})</div>
                </div>
            )
        }
        return <>{name}</>
    }

    return (
        <div className={`game ${isCurrent ? 'current-game' : ''}`}>
            <div className="game-info" onClick={() => setIsOpen(!isOpen)}>
                <span className="game-date">{date}</span>
                <span className="game-teams">
                    {formatName(teams[0])} vs {formatName(teams[1])} vs {formatName(teams[2])} vs {formatName(teams[3])} {teams.length > 4 && "vs"} {teams.length > 4 && formatName(teams[4])}
                </span>
            </div>
            {isOpen && (
                <div className="game-scores">
                    <div className="score-team">
                        <span>{formatName(teams[0])}</span>
                        <span>{score[0]}</span>
                    </div>
                    <div className="score-team">
                        <span>{formatName(teams[1])}</span>
                        <span>{score[1]}</span>
                    </div>
                    <div className="score-team">
                        <span>{formatName(teams[2])}</span>
                        <span>{score[2]}</span>
                    </div>
                    <div className="score-team">
                        <span>{formatName(teams[3])}</span>
                        <span>{score[3]}</span>
                    </div>
                    {teams.length > 4 &&
                        <div className="score-team">
                            <span>{formatName(teams[4])}</span>
                            <span>{score[4]}</span>
                        </div>
                    }
                </div>
            )}
        </div>
    );
}

export default Game;
