import "./App.css";
import Month from "./components/Month";
import Scoreboard from "./components/Scoreboard";
import { useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import { NameModal } from "./components/NameModal";

const supabase = createClient(
  "https://vzhywrjmvskqjqmbwtlt.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZ6aHl3cmptdnNrcWpxbWJ3dGx0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQxMTg0OTEsImV4cCI6MjAxOTY5NDQ5MX0.VfP050kmMS5WzqFLE8z1GkHJfwkC5Spu1ujRqObp5W0",
);

function collectClientMetadata() {
  const metadata = {
    // Browser Information
    userAgent: navigator.userAgent,
    language: navigator.language,
    browserOnline: navigator.onLine,
    platform: navigator.platform,
    cookiesEnabled: navigator.cookieEnabled,

    // Window Information
    windowInnerSize: `${window.innerWidth}x${window.innerHeight}`,

    // Date and Time Information
    timezoneOffset: new Date().getTimezoneOffset(),
    currentTime: new Date().toString(),

    // Performance and Connection
    memory: navigator.deviceMemory || "Unknown", // Not all browsers support this
    hardwareConcurrency: navigator.hardwareConcurrency, // Number of logical processors
    connection: navigator.connection
      ? {
          downlink: navigator.connection.downlink,
          effectiveType: navigator.connection.effectiveType,
          rtt: navigator.connection.rtt,
        }
      : "Unknown",

    // Location (Requires user's permission)
    // Note: This is asynchronous and would require a callback or Promise to handle properly
    location: null,
  };
  return metadata;
}

function handlePermission(setPermissionGranted) {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      handleSubscription();
      setPermissionGranted(true);
    }
  });
}

function handleSubscription() {
  const publicKey =
    "BDz04RwHnyZhvaMJO7bOZzKAzGCFF4BemffDpsoduvaD60F0RzUquqV0m55tpCz8mFNMMpMymGkm6VFoxfFzoeY";
  navigator.serviceWorker.ready.then((registration) => {
    console.log(registration.pushManager);
    registration.pushManager
      .subscribe({
        userVisibleOnly: true,
        applicationServerKey: publicKey,
      })
      .then((subscription) => {
        const json = subscription.toJSON();
        const clientMetadata = collectClientMetadata();
        supabase
          .from("subscription")
          .upsert({
            endpoint: json.endpoint,
            key: json.keys.p256dh,
            auth: json.keys.auth,
            meta: clientMetadata,
            name: localStorage.getItem("name"),
          })
          .then((response) => {
            console.log(response);
            localStorage.setItem("notificationSet", "true");
          });
      })
      .catch((error) => {
        console.error("Failed to subscribe user:", error);
        if (error.toString().includes("already exists")) {
          localStorage.setItem("notificationSet", "true");
        } else {
          localStorage.setItem("notificationSet", "false");
        }
      });
  });
}

function App() {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [games, setGames] = useState([]);
  const [players, setPlayers] = useState([]);
  const [scoreDict, setScoreDict] = useState({});
  const [gamesDict, setGamesDict] = useState({});

  useEffect(() => {
    setModalOpen(localStorage.getItem("name") === null);
    setPermissionGranted(localStorage.getItem("notificationSet") === "true");
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 900000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    supabase
      .from("games")
      .select()
      .eq("year", "2024")
      .then((response) => {
        const games = response.data[0].games;
        setGames(games);

        const players = Array.from(
          new Set(
            games.flatMap((month) => month.games.flatMap((game) => game.teams)),
          ),
        );
        setPlayers(players);

        const tempScoreDict = {};

        games.forEach((month) => {
          month.games.forEach((game) => {
            game.teams.forEach((team, index) => {
              // Extracting individual names from team string
              let names = team.split("(");
              names.forEach((name) => {
                name = name.replace(")", "").trim(); // Remove the closing parenthesis if any
                if (!tempScoreDict[name]) tempScoreDict[name] = 0; // Initialize the person's point if not done yet
                tempScoreDict[name] += game.score[index]; // Add the score to the person's total points
              });
            });
          });
        });
        setScoreDict(tempScoreDict);

        const tempGamesDict = {};
        games.forEach((month) => {
          month.games.forEach((game) => {
            game.teams.forEach((team, index) => {
              // Extracting individual names from team string
              let names = team.split("(");
              names.forEach((name) => {
                name = name.replace(")", "").trim(); // Remove the closing parenthesis if any
                if (!tempGamesDict[name]) tempGamesDict[name] = 0; // Initialize the person's point if not done yet
                tempGamesDict[name] += 1; // Add the score to the person's total points
              });
            });
          });
        });
        setGamesDict(tempGamesDict);
      });
  }, []);

  const [isScoreboardVisible, setIsScoreboardVisible] = useState(false);
  const closeScoreBoard = () => {
    setIsScoreboardVisible(false);
  };

  const handleOnClose = () => {
    handlePermission(setPermissionGranted);
    setModalOpen(false);
  };

  return (
    <div className="App">
      <h1>De Padel Club - 2024 🎾</h1>
      <div id="portal"></div>
      <NameModal open={modalOpen} onClose={handleOnClose} />

      <button
        className="scoreboard-toggle"
        onClick={() => setIsScoreboardVisible(!isScoreboardVisible)}
      >
        {isScoreboardVisible ? "Scorebord" : "Scorebord"}
      </button>

      {!permissionGranted && (
        <button
          className="notifications-button"
          onClick={() => handlePermission(setPermissionGranted)}
        >
          Klik hier voor notificaties
        </button>
      )}

      {isScoreboardVisible && players.length && scoreDict && gamesDict && (
        <Scoreboard players={players} handleClose={closeScoreBoard} scoreDict={scoreDict} gamesDict={gamesDict} />
      )}

      {games.length &&
        games.map((month, index) => (
          <Month key={index} monthData={month} monthIndex={index} />
        ))}
    </div>
  );
}

export default App;
