import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "../App.css"; // You must import or define modal related styles

export const NameModal = ({ open, onClose }) => {
  const [name, setName] = useState("");

  const handleSave = () => {
    if (name !== "" && name.length >= 2) {
      // The if block will close the modal only if the name isn't an empty string.
      console.log(name);
      localStorage.setItem("name", name); // Use your name
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      // It will clear the input every time the modal opens
      setName("");
    }
  }, [open]);

  if (!open) return null;

  return ReactDOM.createPortal(
    <>
      <div className="modal">
        <h2>
          Voor het ontvangen van de wekelijkse padel updates heb ik jullie naam
          even nodig.
        </h2>
        <div className="content">
          <input
            type="text"
            placeholder="Vul hier je naam in"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="actions">
          <button onClick={handleSave}>Opslaan</button>
        </div>
      </div>
    </>,
    document.getElementById("portal"),
  );
};
